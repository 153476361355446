import * as React from "react"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm"
import Container from "../components/Container"
import { PageTitle } from "../components/PageTitles"


const ContactPage = () => {

  return (
    <Layout>
      <Seo title="Contact" description={`Feel free to reach out.`} />
        <PageWrapper>

        <Container size={`small`}>
            <PageTitle>Say Hello</PageTitle>
         </Container>
         
         <Container size={`small`}>
            <ContactForm />
         </Container>
  
        </PageWrapper>
    </Layout>
  )
}

export default ContactPage
